import React from 'react';
import {GatsbyImage} from "gatsby-plugin-image";


const LogosGrid = ({logos}) => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-12 ml-12">
      {logos.map((logo, i) => {
        const maxWidth = logo.width ? `300px` : '150px';
        return (
          <div className="" key={`${logo.name}-${i}`} style={{width: `${logo.width}px`, maxWidth}}>
            <GatsbyImage
              image={logo.image.asset.gatsbyImageData}
              imgStyle={{objectFit: 'contain'}}
              alt={logo.name} />
          </div>
        );
      })}
    </div>
  );
};

export default LogosGrid;
